
.linkWrap {
    margin: 2rem 1rem;
}

.link {
    color: #222222;
    font-weight: bold;
    font-size: 1.3rem;
    white-space: nowrap;
    margin: 0.5rem 0;
}

.description {
    margin: 0.5rem 0;
}

.imageHoverCard {
    width: 25rem;
    height: auto;
    z-index: 100;
    margin: 1rem;
    border-radius: 1rem;
    position: fixed;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    background-color: #cfdecb;
}

.hoverImage {
    width: inherit;
    border-radius: inherit;
}